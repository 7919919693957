import produce from 'immer';

import { TOGGLE_DARKMODE, CHOOSE_PACK } from './constants';

export const initialState = {
  isDarkMode: false,
  pack: 'none'
};

/* eslint-disable consistent-return, no-param-reassign */
export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case TOGGLE_DARKMODE:
        draft.isDarkMode = payload;
        break;
      case CHOOSE_PACK:
        draft.pack = payload;
        break;
      default:
        return state;
    }
  });
